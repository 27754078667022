import React, { useEffect, useState } from "react";
import twitterLogo from "./assets/twitter-logo.svg";
import DuckGameLogo from "./flyer/img/DuckGame.png";
import Rocketsauce from "./assets/PBRSBanner.18e67ba0ba79511579cc.png";
import "./App.css";
import "./flyer/Game.css";
import Game from "./flyer/Game";

// Constants
const TWITTER_HANDLE = "ROCKETSAUCE_";
const TWITTER_LINK = `https://twitter.com/${TWITTER_HANDLE}`;
const ROCKET_LINK = "https://rocketsauce.xyz";

const App = () => {
  // State
  const [walletAddress, setWalletAddress] = useState(null);

  // Actions
  const checkIfWalletIsConnected = async () => {
    try {
      const { solana } = window;

      if (solana) {
        if (solana.isPhantom) {
          console.log("Phantom wallet found!");
          const response = await solana.connect({ onlyIfTrusted: true });
          console.log(
            "Connected with Public Key:",
            response.publicKey.toString()
          );

          /*
           * Set the user's publicKey in state to be used later!
           */
          setWalletAddress(response.publicKey.toString());
        }
      } else {
        alert("Solana object not found! Get a Phantom Wallet 👻");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const connectWallet = async () => {
    const { solana } = window;

    if (solana) {
      const response = await solana.connect();
      console.log("Connected with Public Key:", response.publicKey.toString());
      setWalletAddress(response.publicKey.toString());
    }
  };

  const renderNotConnectedContainer = () => (
    <div className="button-container">
      <button
        className="cta-button connect-wallet-button"
        onClick={connectWallet}
      >
        Connect Wallet
      </button>
    </div>
  );

  const renderConnectedContainer = () => (
    <div className="game-container">
      <Game />
    </div>
  );

  // UseEffects
  useEffect(() => {
    const onLoad = async () => {
      await checkIfWalletIsConnected();
    };
    window.addEventListener("load", onLoad);
    return () => window.removeEventListener("load", onLoad);
  }, []);

  return (
    <div className="app">
      <div className={walletAddress ? "authed-container" : "container"}>
        <div className="header-container">
          <div className="header">
            <img className="duckgame-logo" src={DuckGameLogo} alt="Duck Game" />
          </div>
          <p className="sub-text">🥚 FLY YOUR DUCK AND EARN $EGGZ! 🥚</p>
        </div>

        <div className="body">
          <div className="connected-container">
            {!walletAddress && renderNotConnectedContainer()}
            {walletAddress && renderConnectedContainer()}
          </div>
        </div>

        <div className="footer-container">
          <div className="footer-item">
            <a href={TWITTER_LINK} target="_blank" rel="noreferrer">
              <img
                alt="Twitter Logo"
                className="twitter-logo"
                src={twitterLogo}
              />
            </a>
          </div>
          <div className="footer-item">
            <a href={ROCKET_LINK} target="_blank" rel="noreferrer">
              <img
                src={Rocketsauce}
                className="Powered-by-Rocketsauce"
                alt="powered by ROCKETSAUCE"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="links-container">
        <div className="weblinks">
          <span>
            HI THERE! Thanks for checking out my project - If I'm not in the
            booth, I'm off exploring other people's projects in this awesome
            space! In the demo here you can play a simple skill-based
            play-to-earn game on Solana. For users to play, they must connect
            their wallet to the page, and own a specific NFT, which unlocks the
            game. After playing, the $EGGZ you earn in the game will get
            transferred to your wallet after a 24 hour NFT cooldown -
            functionality pending - EGGZ will be used to purchase new
            accessories and clothing items for your new Dapper Duck V2!
          </span>
        </div>
      </div>
    </div>
  );
};

export default App;
